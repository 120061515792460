.export-transactions {
  &-des {
    margin: 3rem;
  }

  &-warning {
    margin: 3rem;
    color: red;
  }

  &-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 14rem 6rem;

    &-title {
      padding: 0;
    }

    &-content {
      padding: 1rem;
    }
  }

  &-button {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 1rem;
    margin-bottom: 24px;
    @media (min-width: 940px) {
      position: static;
    }
  }
}
