.spending-forecast-page {
  .info-button {
    margin: 0 0 -3px 5px;
  }
  .month-nav-buttons {
    justify-content: center;
  }
  .chart-container {
    height: 262px;
    margin: 0 8px;
  }
  .bill-icon {
    padding-right: 8px;
    vertical-align: text-top;
  }
}
