.highlight {
  position: relative;

  &-default {
    position: absolute;
    width: 100%;
    height: 0.5rem;
    top: 90%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
