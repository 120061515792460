.goals-info-modal {
  .image-container {
    height: 85px;
    width: auto;
    img {
      height: 100%;
      width: auto;
    }
  }
}
