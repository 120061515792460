ion-item::part(native) {
  background: transparent !important;
}

@import "../../../styles/palette";

.recategorization {
  &-content::part(scroll) {
    overflow: hidden;
    display: flex;
  }
  &-column::part(scroll) {
    flex: 1;
    overflow-y: auto;
  }
  &-list {
    border-right: 1px solid $secondary-light;
  }

  &-category {
    &-item {
      --border-color: transparent;
      padding: 2px 0px;
      --padding-start: 8px;
      --padding-end: 0px;
      border-left: 5px solid var(--border-color);
    }

    &-img {
      width: 1.7rem;
      height: 1.7rem;
      margin-right: 0.5rem;

      &-selected {
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 0.5rem;
        left: -5px;
      }
    }
  }

  &-subcategory-item {
    padding: 0.5rem;
    height: 3.5rem;
  }

  &-selected-icon {
    margin-right: 0.3rem;
  }
}
