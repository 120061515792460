.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: start;
}
