@import "../../../styles/palette";

.goal-progress {
  .goal-labels {
    display: flex;
    justify-content: flex-start;
  }
  .progress-bar {
    width: 100%;
    background-color: $medium-gray;
    border-radius: 4px;
    .progress-indicator {
      height: 8px;
      margin-top: 4px;
      background-color: var(--progress-color, $progress-fill);
      border-radius: 4px;
      max-width: 100%;
    }
  }
}
