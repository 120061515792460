@import "../../../styles/palette";

ion-header#onboarding-nav-header {
  background: $white;
  ion-toolbar {
    --min-height: 69px;
    .nav-title {
      > * {
        max-width: 330px;
        margin: auto;
      }
      img {
        max-height: 32px;
        margin-top: 8px;
      }
    }
    @media (min-width: 940px) {
      --min-height: 96px;
    }
  }
}
