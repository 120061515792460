@import "../../../styles/palette";

.policies-page {
  .policy-links-list {
    ion-item {
      --min-height: 25px;
      a {
        text-decoration: none;
        color: $link-blue;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
