.goal-detail {
  .status-banner {
    background-color: #edeae0 !important;
    padding: 10px;
    @media (min-width: 940px) {
      margin: 16px;
      border-radius: 10px;
    }
  }
  .split-pane {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .category-icon {
      height: 87px;
      width: 87px;
    }
    ion-buttons {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .goal-progress {
    width: 100%;
  }
  .inactive-status {
  }
}
.goal-detail-alert {
  .alert-message {
    white-space: break-spaces;
  }
}
