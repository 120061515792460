.profile-page {
  .profile-button {
    width: 45%;
    margin: auto;
    margin-bottom: 10%;
  }
}

.partner-pending-invite {
  color: red;
  margin-top: 2rem;
}
