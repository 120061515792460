@import "../../../styles/palette";
@import "../../../styles/typography";
$transition-time: 750ms;

ion-content#BillsDetail {
  .bills-monthly-total-list-section {
    background: $tertiary-light !important;
  }
  #bills-calendar {
    .week-row:last-child {
      border-bottom: 1px solid $light-gray;
    }
    .week-row {
      border-top: 1px solid $light-gray;
      ion-col:not(:last-child) {
        border-right: 1px solid $light-gray;
      }
      ion-col.ion-text-center {
        padding: 15px 0px;
        &.placeholder-text {
          padding: 15px 0px;
        }
      }
    }
    .week-row:first-child {
      border-top: none;
      ion-col:not(:last-child) {
        border-right: none;
      }
    }
    div.today {
      position: absolute;
      left: 20%;
      top: 25%;
      border: 1px solid $brand-base;
      height: 50%;
      width: 60%;
      border-radius: 5px;
      display: inline;
      background: $white;
      div {
        color: $primary-text !important;
        display: none;
      }
    }
    .selected-week {
      background: $primary-blue;
      color: $white;
      @include font-md;
      div.today {
        border-color: $brand-base;
        div {
          display: block !important;
        }
      }
    }
    div.bullets.font-md {
      position: absolute;
      bottom: -4%;
      width: -webkit-fill-available;
      justify-content: center;
      div {
        display: contents;
        width: 100%;
      }
    }
    .selected-week div.bullets div {
      -webkit-text-stroke: 1px $white;
      text-stroke: 1px $white;
      &.placeholder-text {
        -webkit-text-stroke-width: 0;
      }
    }
  }
}

.paid-stamp {
  background: $income;
  border-radius: 2px;
  padding: 2px 5px;
  font-weight: bold;
}
