.spending-analysis {
  .main-container {
    height: 200px;
    width: 360px;
    max-width: 100%;
    margin: auto;
    position: relative;
    .percentage-label-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 104px;
      .percentage-label {
        z-index: 1;
        width: fit-content;
        height: fit-content;
        margin-left: 8px;
        font-size: 20px;
        color: var(--ion-color-primary);
        font-weight: 600;
      }
    }
    .BudgetPieChart {
      max-height: 106px;
      margin: 26px 0 26px;
    }
    .category-picker {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 26px -16px 0 -16px;
      .category-icon {
        max-width: 35px;
      }
      .category-labels {
        display: flex;
        flex-direction: column;
        color: var(--ion-color-primary);
        max-width: 122px;
        ion-label {
          margin-bottom: 4px;
          width: 122px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.spending-analysis-card .category-picker {
  margin: 26px -32px 0 -32px !important;
}
