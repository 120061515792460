@import "./../../../styles/palette";

ion-toolbar#TransactionSearch {
  & > div {
    display: flex;
  }
}

ion-toolbar#TransactionAdvancedSearchResult {
  @media (min-width: 940px) {
    background-color: $white;
    padding-left: var(--ion-margin, 16px);
  }
}
