@import "../../../styles/palette";
.new-goal {
  .new-goal-options {
    ion-item {
      overflow: visible;
      font-weight: 500;
      > ion-button {
        align-self: flex-start;
        margin-left: 0;
      }
    }
  }
}
