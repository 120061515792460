@import "../../../styles/palette";

.transaction {
  &-items {
    &-header {
      overflow-wrap: anywhere;
    }
    &-amount {
      overflow-wrap: normal;
    }
    &-icon {
      margin-right: 0.8rem;
    }
    &-category-row {
      margin-top: -7px;
    }
    &-reaction {
      width: 1rem;
      margin-right: 0.8rem;
      display: inline-block;
    }
  }

  ion-avatar {
    margin-top: 10px;
    margin-bottom: auto;
  }

  &-detail {
    &-header {
      // !important is needed to override the section card white background:
      background-color: $tertiary-light !important;
    }
    &-row {
      width: 100%;
    }

    &-comments {
      > ion-icon {
        margin-bottom: 6px;
      }
      > ion-textarea {
        background-color: $quaternary;
        padding-left: var(--ion-margin, 8px);
        border-radius: 4px;
        margin: 0 12px 2px 12px;
      }
      > ion-button {
        height: 37px;
        &.ios {
          height: 35px;
        }
      }
    }

    &-category {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $quaternary;
      border-radius: 0.5rem;
    }
  }

  &-comments-reaction {
    img {
      width: 1.5rem;
      margin: 0.2rem;
      margin-right: 1.5rem;
    }

    p {
      display: inline-block;
    }
  }

  &-comments-reaction::part(native) {
    padding-left: 0;
  }

  &-create {
    border: 1px solid $brand-base;
    border-radius: 4px;
  }
  .offer-icon {
    width: 28px;
    height: auto;
  }
}

ion-modal#TransactionRuleAlertModal {
  --max-width: 80vw;
  --max-height: 35vh;
  --background: white;
  --backdrop-opacity: 50%;
}

@media (min-width: 940px) {
  .transaction-search-section {
    // a bit random but the ion-searchbar has 3px 7px padding
    // so this makes it 24px/16px (defaults for desktop nav-header)
    margin: 21px 0 21px 9px;
    justify-content: space-between;
    ion-searchbar {
      width: 50%;
      --box-shadow: none;
      input.searchbar-input {
        border: 1px solid #7b7a6f;
        border-radius: 4px;
        background: #ffffff;
        &:focus {
          background: #eeeeee;
        }
      }
    }
  }
  ion-button.clear-filter-button {
    color: $link-blue !important;
    text-transform: none !important;
    font-weight: 400;
    margin-right: 4px;
  }
  .advanced-search-result {
    padding: 24px 16px 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    > div {
      margin-right: 16px;
    }
  }
}

@media (max-width: 939px) {
  .transaction-search-section {
    padding-bottom: 16px;
    ion-searchbar {
      padding-top: 0;
      .searchbar-input-container {
        border-radius: 4px;
        border: none;
        input[type="search"].searchbar-input {
          height: 34px;
          box-shadow: none;
          background: #eeeeee;
          padding-right: 40px;
        }
        ion-icon.searchbar-search-icon.md {
          transform: translateY(-3.5px);
        }
      }
    }
  }
}
