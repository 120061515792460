@import "./../../../styles/typography";

.tabs-container {
  @media (min-width: 940px) {
    ion-router-outlet.ion-page {
      > .ion-page {
        > ion-content {
          .desktop-content-container {
            --content-width: 680px;
            min-width: var(--content-width);
            box-sizing: content-box;
            padding: 120px calc((100vw - var(--content-width)) / 2) 24px;
            min-height: calc(100vh - 144px);
            background: #f8f8f8;
            --ion-background-color: #f8f8f8;
            .fy-section-card {
              --ion-background-color: #ffffff;
              background: #ffffff;
              box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
              border-radius: 10px;
              margin: 24px 0;

              // This is to keep the border-radius for toolbars that are within a section card:
              overflow: hidden;
              > * {
                background: unset;
              }

              .fy-section-title-border {
                padding: 0 0 16px 0;
                margin: 16px 16px 0 16px;
                border-bottom: 1px solid #e6e6e6;
              }
            }
            // useful for footers that need to be outside of any cards:
            .fy-transparent-section {
              background-color: transparent;
            }
          }
        }
      }
    }
    .tabs-bar {
      display: none;
    }
  }
}
ion-app.ios {
  .tabs-bar > ion-tab-button::after {
    top: 2.35px !important;
  }
  .desktop-nav-menu ion-item::after {
    top: 7.35px;
    left: 17.5px;
  }
}
.tabs-bar,
.desktop-nav-menu {
  ion-tab-button {
    ion-label {
      @include font-xs;
    }
    &::after {
      top: 5px !important;
      left: calc(50% - 15px) !important;
    }
  }
  ion-tab-button,
  ion-item {
    &.sub-link {
      display: none;
      @media (min-width: 1440px) {
        display: block;
        ion-label {
          color: #181818;
          @include font-sm;
        }
      }
    }
    position: relative;
    &::after {
      content: "";
      height: 30px;
      width: 30px;
      background: var(--tab-theme-color);
      position: absolute;
      top: 9.5px;
      left: 13px;
      transform: scale(0);
      border-radius: 50%;
      opacity: 0;
      transition: opacity, transform;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.35, 1.65, 0.76, 1.65);
      transition-delay: 0.5s;
    }
    & > ion-icon {
      position: relative;
      display: block;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding-top: 1px;
      height: 25px; /* Height of new image */
      @media (min-width: 940px) {
        margin-inline-end: 16px;
      }
    }
    &:not(.active, .tab-selected):not(.sub-link) {
      ion-label {
        color: #ccc;
      }
    }
    &:active,
    &.ion-activated,
    &.active,
    &.tab-selected {
      ion-label {
        color: #181818;
        font-weight: 700 !important;
      }
      &::after {
        transform: scale(1);
        opacity: 1;
        transition-delay: unset;
      }
    }
    &#tab-button-more,
    &#menu-item-more {
      div {
        margin-top: 2px;
        margin-bottom: 2px;
        width: 25px !important;
        height: 25px !important;
        svg,
        img {
          opacity: 0.5;
        }
        svg {
          margin-top: 4px;
          width: 20px !important;
          height: 20px !important;
          text-align: center;
          text {
            font-size: 12px;
          }
        }
      }
      &:active,
      &.ion-activated,
      &.active,
      &.tab-selected {
        svg,
        img {
          opacity: 1;
        }
      }
    }
  }
}
