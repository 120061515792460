#home-tab.ion-page {
  ion-spinner {
    min-height: 13vh;
  }
  ion-content {
    --background: #f8f8f8;
  }
  .cards-container {
    background: #f8f8f8;
    padding-top: 16px;
    margin-top: -16px;
    > div > ion-card {
      --ion-background-color: #ffffff;
      margin: 16px;
      padding: 16px;
      @media (min-width: 940px) {
        margin: 24px 0 0 0;
      }
    }
  }
  @media (min-width: 1440px) {
    .desktop-content-container {
      padding: 120px calc((100vw - 856px) / 2) 0;
      .cards-container {
        display: flex;
        > div {
          width: 50%;
          &:nth-child(1) {
            > ion-card {
              margin: 24px 12px 24px 0;
            }
          }
          &:nth-child(2) {
            > ion-card {
              margin: 24px 0 24px 12px;
            }
          }
        }
      }
    }
  }
}
