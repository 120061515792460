.no-internet-ui-alert,
.accounts-ui-alert {
  z-index: 99999;
  margin: auto;
}

.no-internet-ui-alert {
  margin-top: calc(var(--ion-safe-area-top) + var(--ion-margin, 16px));
}

.accounts-ui-alert {
  margin-top: var(--ion-margin, 16px);
  padding-left: var(--ion-margin, 16px);
  padding-right: var(--ion-margin, 16px);
  button {
    width: 100%;
  }
  @media (min-width: 940px) {
    margin-top: 24px;
    padding-left: unset;
    padding-right: 0;
  }

  @media (min-width: 1440px) {
    padding-right: 12px;
  }
}
