.password-indicator {
  ion-col {
    display: flex;
    align-items: center;
  }
  &.active {
    color: red;
  }
  &.valid {
    color: green;
  }
  &:not(.active) {
    &:not(.valid) {
      ion-icon {
        color: #cccccc;
      }
    }
  }
}
