.spending-forecast-chart {
  width: 100%;
  min-height: 100%;
  height: 242px;
  #tooltip-root {
    position: relative;
    .slice-tooltip {
      position: absolute;
      z-index: 1;
      text-align: center;
      letter-spacing: 0.5px;
      .tooltip-date {
        font-size: 12px;
        color: "#000000";
        background: rgba(255, 255, 255, 0.7);
      }
      .tooltip-box {
        margin-top: 4px;
        border: 1px solid #000000;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 2px;
        padding: 6px 13px;
        font-size: 10px;
        font-weight: 600;
        .amount {
          padding: 2.5px 0;
        }
      }
    }
  }
  svg {
    overflow: visible !important;
  }
}
