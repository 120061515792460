.balance-list {
  .account-label {
    // make sure to wrap instead of pushing the (up to 6 figure) value out of alignment:
    max-width: calc(100% - 50px);
    line-height: 1.4;
  }
  .account-logo-icon,
  .type-icon {
    margin-left: 0;
    margin-right: 10px;
    position: relative;
    top: 1px;
    min-width: 22px;
  }
  .type-icon {
    top: 2px;
  }
  .amount.positive {
    color: #00c264;
  }
}
