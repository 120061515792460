@import "../../../styles/palette";

.income-card {
  background-color: $income;
  color: $white;
  position: relative;

  &-header {
    color: $white;
  }

  &-close {
    position: absolute;
    right: 0;

    &-icon {
      color: $white;
    }
  }
}
