@import "../../../styles/palette";

ion-content#EditSharedStatus {
  ion-item {
    border: 1px solid $medium-gray;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    &.edit-sharedStatus-item-selected {
      border-color: $primary-blue;
      ion-icon[slot="end"] {
        font-size: 1.5rem;
        color: $primary-blue;
      }
    }
  }
}
