.goal-summary-card {
  min-height: fit-content;
  text-align: center;
  .private-icon {
    position: absolute;
    right: 32px;
  }
  ion-card-content {
    ion-icon.category-icon {
      font-size: 70px;
    }
    ion-icon.status-icon {
      font-size: 22px;
      vertical-align: sub;
      margin: 0 5px;
      &.completed {
        color: green;
      }
      &.closed {
        color: red;
      }
    }
    .balance-amount {
      font-size: 18px;
      font-weight: bold;
    }
  }
  @media (min-width: 940px) {
    margin: 24px 0;
    ion-card-content {
      max-width: 312px;
      margin: auto;
    }
  }
}
