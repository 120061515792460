@import "./../../../styles/palette";

ion-toolbar#TransactionAdvancedSearchResult {
  background: $tertiary-light;
  --background: $tertiary-light;
  ion-col {
    margin: 0;
    margin-bottom: 2px;
    padding: 0;
  }
  .search-params {
    ion-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (min-width: 940px) {
        white-space: normal;
      }
    }
  }
}
