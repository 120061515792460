@import "../../../styles/palette";

svg.user-avatar,
div.user-avatar {
  border-radius: 30px;
  height: 40px !important;
  width: 40px !important;
  border: 1px solid $medium-gray;
  img {
    border-radius: 30px;
    height: 100%;
    width: auto;
    max-width: 100%;
    min-width: 100%;
    object-position: 50% 50%;
    object-fit: cover;
  }
}

div.ownership-avatars {
  display: flex;
  justify-content: center;
  position: relative;
  &.align-end {
    justify-content: flex-end;
  }
  &.align-start {
    justify-content: flex-start;
  }
  svg.user-avatar,
  div.user-avatar {
    height: 25px !important;
    width: 25px !important;
  }
  ion-icon {
    font-size: 20px !important;
  }
  &.xs {
    svg.user-avatar,
    div.user-avatar {
      height: 15px !important;
      width: 15px !important;
    }
    ion-icon,
    text {
      font-size: 10px !important;
    }
  }
  &.md {
    svg.user-avatar,
    div.user-avatar {
      height: 36px !important;
      width: 36px !important;
    }
    ion-icon {
      font-size: 30px !important;
    }
  }
  &.lg {
    svg.user-avatar,
    div.user-avatar {
      height: 50px !important;
      width: 50px !important;
    }
    ion-icon {
      font-size: 40px !important;
    }
  }
  &.vertical-avatars {
    display: block !important;
    text-align: center;
    & i {
      background: none;
      margin-left: 0px;
    }
    .margin-minus {
      margin-left: 0px;
      margin-top: -12px;
    }
    > div {
      text-align: -webkit-center;
    }
  }
  &.diagonal-avatars > div:nth-child(2) {
    margin-top: 15px;
  }
  &.xs.diagonal-avatars > div:nth-child(2) {
    margin-top: 7px;
    margin-left: 7px;
  }
}

.margin-minus {
  margin-left: -15px;
}
.margin-minus-v {
  margin-top: -12px;
}
