@import "../../../styles/";

ion-content.onboarding-bg {
  background-color: $light-gray;
  --ion-background-color: $light-gray;
}

.static-footer-container {
  ion-footer {
    position: absolute;
    top: calc(var(--original-height) - 126px);
  }
}

.container {
  margin-top: 24px;
  @media (min-width: 940px) {
    --content-width: 680px;
    min-width: var(--content-width);
    box-sizing: content-box;
    padding: 72px calc((100vw - var(--content-width)) / 2) 0;
    .onboarding-copy {
      h1 {
        margin-bottom: 24px;
        padding-bottom: 16px;
        border-bottom: 1px solid $border-gray;
      }
    }
    .bounded {
      padding-left: 132px;
      padding-right: 132px;
    }
  }
  ion-icon,
  div.inline-block {
    vertical-align: middle;
  }
  div.inline-block {
    margin-left: 2px !important;
  }
  #ion-grid-pw-icons {
    margin-bottom: 75px !important;
  }
  strong {
    font-size: 20px;
    line-height: 26px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }

  a {
    text-decoration: none;
  }
}

.card-content-container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  transform: translateY(-50%);
  h4 {
    margin-top: 30px;
  }
  div.ion-margin-top {
    margin-top: 30px;
  }
}

div#welcome-page {
  ::part(scroll) {
    --overflow: hidden;
  }
  ion-slides#slides-container {
    height: 100%;
    @media (max-height: 568px) {
      height: 70vh;
    }
    ion-card {
      background-color: $white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      > img {
        height: 25vh;
      }
    }
    .swiper-wrapper {
      margin-bottom: 10px;
    }
    .swiper-pagination {
      bottom: 0;
    }
  }
}

ion-item.share-linked-account-item {
  h4 {
    margin: 0;
    max-width: calc(100vw - 226px);
  }
}
