.finances-header {
  h2,
  p {
    margin: 20px;
  }
}
.other-field {
  margin-right: 0.5rem;
}
.other-goals-text {
  display: none;
}

.other-goals-text-after {
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 20px;
  width: 90%;
  padding-left: 10px;
  animation: slide-down 0.5s;
  width: 90%;
  height: 100%;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
