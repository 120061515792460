.chart-legend {
  justify-content: space-around;
}

.chart-legend,
.info-modal-legend {
  display: flex;
  .legend-label {
    display: flex;
    align-items: center;
  }
  .actual::before {
    background-color: #1c7fff;
  }
  .forecast::before {
    background-color: #c4c4c4;
  }
  .actualIncome::before {
    background-color: #00c264;
  }
  .budgetedIncome::before {
    background-color: #00c264;
  }
  .budgetedSpend::before {
    background-color: #181818;
  }
  .prevMonth::before {
    background-color: #181818;
  }
  .compare::before {
    display: none;
  }
  ion-select::before,
  .legend-label::before {
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 8px;
  }
  ion-select::part(icon) {
    content: url(../../../assets/images/chevrons/chevron-down-outline.svg);
    margin-left: 0.3em;
    margin-top: 0.2em;
    opacity: 1;
  }
  ion-select {
    margin-right: 0.2em;
    height: 2rem;
    --placeholder-opacity: 1;
    --placeholder-color: var(--ion-color-primary);
    padding-left: 0;
    padding-right: 0.5em;
    border: 1px solid var(--ion-color-primary);
    border-radius: 8px;
    &[value="compare"] {
      font-weight: 600;
      font-size: 16px;
      padding-left: 10px;
      text-align: center;
      min-width: 115px;
      width: 20%;
    }
  }
}
