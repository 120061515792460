@import "../../../styles/palette";

.fy-nav-header {
  background: $white;
  ion-toolbar {
    --background: $white;
    ion-back-button {
      font-size: 14px;
      --icon-font-size: 18px !important;
      text-transform: none;
      padding: 0 16px;
    }
  }
  ion-title {
    line-height: normal;
    img {
      height: 4vh;
    }
    &.main-header-title {
      font-family: IvyPresto Headline;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
    .header-title-image {
      height: 32px;
      margin-top: 8px;
    }
  }
  @media (min-width: 940px) {
    &::after {
      background-image: none;
    }
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    text-align: left;
    padding-top: 0;
    ion-toolbar {
      ion-back-button {
        padding: 0 16px 0 4px; // since the button already has 12px padding
      }
      .fy-nav-buttons {
        margin-right: 8px; // since ion-buttons already have 8px of padding
        color: $link-blue;
        text-transform: none;
        .nav-button-label {
          margin-left: 6px;
          font-weight: normal;
          text-transform: none;
        }
      }
      &.title-section {
        ion-title.main-header-title {
          font-size: 40px;
          line-height: 48px;
        }
        hr.title-section-border {
          display: none;
        }
        &.has-subheader {
          hr.title-section-border {
            display: block;
            position: absolute;
            border-bottom: 1px solid #e6e6e6;
            width: calc(100% - 32px);
            left: 16px;
            margin: 0;
            bottom: 0;
          }
        }
        ion-title {
          padding-bottom: 16px;
        }
        &:not(.has-back-button) {
          ion-title {
            padding-top: 16px;
          }
        }
      }
      &.subheader {
        padding-bottom: 8px;
      }
    }
    ion-title {
      text-align: left;
    }
  }
}
