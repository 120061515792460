.confetti-wrapper {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  .confetti {
    position: absolute;
  }
  $colors: (#bf4641, #a6b6ba, #bfbf41, #447dae, #be9fb0);
  @for $i from 0 through 150 {
    $w: random(20);
    $l: random(200);
    .confetti-#{$i} {
      width: #{$w}px;
      height: #{$w * 0.4}px;
      background-color: nth($colors, random(5));
      top: -10%;
      left: unquote($l + "%");
      opacity: random() + 0.5;
      transform: rotate(#{random() * 360}deg) skew(random() * 45deg, random() * 45deg);
      animation: drop-#{$i} unquote(4 + random() + "s") unquote(2 * random() + "s") 1;
    }

    @keyframes drop-#{$i} {
      100% {
        top: 110%;
        left: unquote($l + random(15) + "%");
        transform: rotate(#{random() * 360}deg) skew(random() * 45deg, random() * 45deg);
      }
    }
  }
}
