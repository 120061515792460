.celebrate-activation .confetti-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin: 40px auto;
  }
  h4 {
    margin: 16px;
  }
}
