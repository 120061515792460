.spending-list {
  height: 100%;
}

ion-progress-bar {
  height: 0.5rem;
  border-radius: 13px;
  margin-top: 4px;
}

.spendingByPersonProgressBar {
  margin: 0.5rem 0;
}
