@import "../../../styles//palette";

.net-worth-page {
  .chart-container {
    margin: 0 16px;
    overflow-y: hidden;
    overflow-x: auto;
    direction: rtl; // Start scrolled all the way to the latest month
    .chart-wrapper {
      height: 250px;
    }
  }
  .with-underline {
    display: flex;
    width: fit-content;
    margin-left: auto;
    flex-direction: column;
    align-items: flex-end;
    span {
      position: relative;
      bottom: 12px;
      right: 12.5px;
      display: block;
      height: 12px;
      width: 100%;
      opacity: 0.5;
      z-index: -1;
      &.positive {
        background: $positive-balance-green;
      }
      &.negative {
        background: $negative-balance-gray;
      }
    }
  }
  .totals-toolbar {
    border-top: 1px solid $border-gray;
  }
}
