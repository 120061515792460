@import "../../../styles/palette";

#CashFlowDetail {
  ion-thumbnail {
    margin-right: 0;
    img {
      width: auto !important;
      max-height: 80%;
      padding-top: 12px;
    }
  }
  ion-footer {
    border-top: 1px solid $light-gray;
    background: $white;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
