// Radio Group (see ChangeCategoryButton.tsx for example usage)
ion-radio-group {
  ion-item.item {
    --border-color: #1c7fff;
    --background-color: rgba(28, 127, 255, 0.1);
    border: 1px solid rgba(24, 24, 24, 0.5);
    border-radius: 8px;
    margin: 1rem;
    padding: 6px 0;
    ion-text {
      max-width: calc(100% - 58px); // So that the text does not encroach on the radio
      margin-right: 0;
    }
    &.item-radio-checked {
      border-color: var(--border-color);
      background-color: var(--background-color);
      ::part(mark) {
        --color-checked: var(--border-color);
      }
    }
  }
}

// Ion Range (see AmountInputSlider.tsx for example usage)
ion-range {
  --range-color: #1c7fff;
  --bar-background: rgb(24, 24, 24, 0.1);
  --bar-background-active: var(--range-color);
  --bar-height: 8px;
  --bar-border-radius: 8px;
  --knob-background: #ffffff;
  --knob-box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
    0 0 0 1px rgba(0, 0, 0, 0.02), inset 0px 0px 0px 3px var(--range-color);
}

ion-title,
ion-card-title {
  &:not(.font-title-1, .font-title-2, .font-xl, .font-lg, .font-md, .font-sm, .font-xs) {
    @include font-title-3;
    line-height: 1.5em;
  }
}

ion-card {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

ion-button {
  --border-radius: 8px;
  @include font-md;
  &[size="large"] {
    @include font-lg;
    --padding-start: 32px;
    --padding-end: 32px;
  }
  &[size="small"] {
    @include font-sm;
  }
  text-transform: none;
  &.button-disabled {
    --color: #999999;
    --background: #e6e6e6;
    --box-shadow: none;
    opacity: 1;
  }
}

ion-item-divider {
  --background: $white;
  --color: $brand-base;
  margin-top: var(--ion-margin, 16px);
  & > ion-label {
    @include font-title-3;
  }
}

ion-progress-bar {
  --background: transparent;
  &:not(.dynamic) {
    --progress-background: #1c7fff;
  }
}

ion-footer {
  background: $white;
}

ion-modal ion-footer {
  margin-bottom: var(--ion-safe-area-bottom);
}

ion-item,
ion-item > ion-label {
  &:not(.font-title-1, .font-title-2, .font-xl, .font-lg, .font-md, .font-sm, .font-xs) {
    @include font-md;
  }
}

ion-item.item-input {
  ion-label[position="stacked"] {
    @include font-md;
  }
}

ion-input {
  @include font-md;
}

ion-item.sc-ion-select-popover {
  --border-style: none;
}

// this is specifically for Avatars component
ion-item > ion-avatar[slot="start"] {
  height: auto;
}

ion-toast {
  --background: #181818;
  --color: #ffffff;
}

.fy-feathered {
  box-shadow: 0px -25px 25px 0px rgba(255, 255, 255, 0.75);
}
