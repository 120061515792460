.top-spending-sub-categories-card {
  .category-image-container {
    width: 60px;
  }
  ion-grid {
    padding-top: 0;
    padding-bottom: 0;
    ion-col {
      padding-top: 0;
      padding-bottom: 0;
      ion-row {
        padding: 1px 0;
        &.name {
          font-size: 16px;
          font-weight: 500;
        }
        &.amount {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 2px;
        }
      }
    }
  }
}
