@import "../../../styles/palette";

#BudgetCategoryDetail {
  ion-grid > ion-row.font-md {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  ion-grid > ion-row.font-md:nth-child(5) {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid $medium-gray;
  }
}
