.desktop-nav-bar {
  display: none;
  @media (min-width: 940px) {
    display: flex;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: content-box;
  padding: 0 12px;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  height: 96px;
  z-index: 105;
  background: #ffffff;
  ion-buttons ion-button {
    overflow: visible;
    height: 100%;
    .firstly-logo {
      height: 48px;
      max-width: 130px;
      margin: 12px 0px;
    }
  }
  .nav-item {
    padding: 30px 16px;
    line-height: 24px;
    ion-button {
      margin: 0;
      vertical-align: middle;
      text-transform: none;
      font-size: 16px;
      font-family: Inter, "Franklin Gothic Medium", Tahoma, sans-serif;
      font-weight: 400;
      --padding-start: 0px;
      --padding-end: 0px;
      --background-hover: #ffffff;
      --overflow: visible;
      svg {
        transform: scale(0.65);
      }
    }
  }
}
