@import "./../../../styles/palette";

img#UploadDocImg {
  height: 65px;
}
div#NoMoreBillsUploadDiv {
  bottom: 20px;
  margin: auto;
  width: 100%;
}

ion-button#NoMoreBillsUploadButton {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  background: $white;
  color: $black;
  padding: 15px 15px;
  border: 1px solid black;
  border-radius: 10px;
}
.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  background: white;
  border: 1px solid black;
}
