.goal-transaction-item {
  --icon-width: 53px;
  .ownership-avatars,
  .savings-bonus-logo {
    width: var(--icon-width);
    padding-right: 8px;
  }
  .transaction-text {
    max-width: calc(100% - var(--icon-width));
    h4 {
      margin: 0;
      font-weight: 600;
    }
    h6 {
      margin: 6px 0;
    }
    .transfer-account-name {
      white-space: nowrap;
      width: 100%;
      max-width: 100%;
      min-width: 0px;
      display: inline-flex;
      overflow: hidden;
      .account-name,
      .institution-name {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: max-content;
      }
      .account-name {
        flex: 1;
      }
      .account-number {
        width: max-content;
      }
    }
  }
}
