@import "../../../../node_modules/@ionic/react/css/display.css";

ion-label#BillsAuditDOB {
  pointer-events: all;
}

ion-avatar#BillerLogoAvatar {
  border: 1px solid black;
  img {
    object-fit: contain;
  }
}

.select-full-width {
  max-width: 100% !important;
  width: 100% !important;
  padding-left: 0 !important;
  border: 0px;
  padding: 10px 10px 10px 0px;
  background-color: transparent;
}

select:required:invalid {
  color: grey;
}

option {
  color: black !important;
}

input#BillsAuditDOBInput:required:invalid {
  color: grey;
}

input#BillsAuditDOBInput:required:valid {
  color: black !important;
  -webkit-calendar-picker-indicator: {
    background: white;
  }
}

input[type="date"] {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-inline-flex;
  overflow: hidden;
  padding: 0;
  -webkit-padding-start: 1px;
}

input::-webkit-datetime-edit {
  -webkit-flex: 1;
  flex: 1;
  -webkit-user-modify: read-only !important;
  display: inline-block;
  min-width: 0;
  overflow: hidden;
}

input::-webkit-datetime-edit-fields-wrapper {
  -webkit-user-modify: read-only !important;
  display: inline-block;
  padding: 1px 0;
  white-space: pre;
}

.basic-input-div {
  margin-left: 16px;
  margin-inline-start: 16px;
  align-items: center;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
}

.basic-input {
  border: 0px;
  padding: 10px 10px 10px 0px;
  width: 100%;
}

div#NextButtonDiv {
  bottom: 20px;
  margin: auto;
  width: 100%;
  text-align: center;
}

ion-button#NextButton {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
