ion-modal#MarkTransactionAsBillModal {
  ion-label:first-child {
    max-width: 35vw;
  }
  ion-select,
  ion-datetime {
    max-width: 65vw;
    text-align: end;
  }
}
