@import "../../../styles/palette";

.item-date-divider {
  border: none;
  @media (min-width: 940px) {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 16px;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 32px;
    margin-bottom: 16px;
  }
}
