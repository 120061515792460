@import "./palette";

.inline-block {
  display: inline-block !important;
}

.selecteActionSheet {
  max-height: 10rem;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-24 {
  margin-top: 24px;
}

.text-no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wrapped-text-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.text-wrap {
  overflow: inherit;
  white-space: normal;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline-logo {
  margin-right: 8px;
  margin-bottom: 1px;
}

.sub-icon {
  --width: 18px;
  &.sub-icon-xs {
    --width: 8px;
  }
  &.sub-icon-sm {
    --width: 14px;
  }
  &.sub-icon-lg {
    --width: 28px;
  }
  position: relative;
  width: var(--width);
  height: var(--width);
  align-self: flex-end;
  margin-left: calc(var(--width) * -1);
  margin-top: calc(var(--width) * -1);
}

.button-aligned-left {
  // accounts for 8px of padding before button content:
  margin-left: -8px;
}

.weblink {
  cursor: pointer;
  color: $link-blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #dddddd;
  &.dashed-bottom-border:last-of-type {
    display: none;
  }
}

.dashed-bottom-border {
  @media (min-width: 940px) {
    border-bottom: 1px dashed #e6e6e6;
  }
}

.half-padding {
  padding: 8px;
}

.inline-ml-8 {
  margin-inline-start: 8px !important;
}

.text-faded {
  opacity: 0.6;
}

.text-margins {
  margin-top: 4px;
  margin-bottom: 4px;
}

ion-item.no-inner-padding {
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.hidden-mobile {
  display: none;
  @media (min-width: 940px) {
    display: revert;
  }
}

.hidden-desktop {
  @media (min-width: 940px) {
    display: none;
  }
}

.limit-desktop-width {
  @media (min-width: 940px) {
    max-width: var(--max-width, 375px);
    margin: auto;
  }
}

.hidden {
  visibility: hidden;
}

.text-muted {
  letter-spacing: 0.5px;
  color: $dark-gray;
}
