div#BillsAuditSubmitDiv {
  position: fixed;
  bottom: 20px;
  margin: auto;
  width: 100%;
  @media (min-width: 940px) {
    position: static;
    padding: 24px;
  }
}

ion-button#BillsAuditSubmitButton {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
