@import "../../../styles/palette";

.amount-input-slider {
  .input-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ion-input {
      max-width: 95px;
      input {
        border: 1px solid $brand-base;
        border-radius: 8px;
        font-weight: 600;
        padding: 8px;
        margin: 8px;
        text-align: center;
        &:focus {
          border: 1px solid $primary-blue;
        }
      }
    }
  }
}
