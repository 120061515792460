ion-modal#EditCategoriesModal {
  .category-header {
    border: 3px solid var(--category-theme-color);
    border-left: none;
    border-right: none;
    &:last-of-type {
      border-bottom: none;
    }
    ion-thumbnail {
      margin-right: 0;
      img {
        width: auto !important;
        max-height: 80%;
        padding-top: 12px;
      }
    }
  }
}
