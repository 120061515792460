@import "../../../styles/palette";

ion-item.BudgetDetailItem:not(:first-child) {
  margin-top: 10px;
  ion-label {
    overflow: unset;
  }
  ion-label:last-child {
    max-width: 20vw !important;
  }
}

.budget-list-status {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px !important;
}

.budget-header-status {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.over-budget-pill {
  background: #ef0033;
  border-radius: 10px;
  color: white;
  padding: 2px 7px 2px 7px !important;
  height: 100%;
}
