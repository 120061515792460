.account-detail-modal {
  .ion-page {
    justify-content: flex-start;
    ion-item {
      --inner-padding-end: 18px;
    }
  }
  .header-md {
    margin-bottom: 24px;
    &::after {
      display: none;
    }
  }
  .institution-title {
    display: flex;
    align-items: center;
    justify-content: center;
    .account-logo-icon {
      margin-left: 0; // overrides the default margins to center it properly here
    }
  }
  .account-name {
    text-align: center;
  }
}
