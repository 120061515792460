.comments-list {
  width: 100%;
  padding-right: 0;

  &-date {
    padding-right: 0;
  }
}

.reactions-avatar {
  margin-top: 8px;
}

ion-item.CommentItem {
  ion-avatar.ion-no-margin {
    padding-top: var(--ion-margin, 16px);
    margin-top: 0px !important;
  }
}
