.payday-funding {
  ion-item {
    ion-button {
      align-self: flex-start;
      padding-bottom: 3rem;
      max-width: calc(100vw - 140px);
      ion-text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
