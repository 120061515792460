@import "../AccountsTab//AccountsTab";

.account-item {
  max-height: 60px !important;
  .account-logo-icon {
    margin-left: 0px;
    min-width: 22px;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }
  padding-right: 12px;
  .account-text {
    display: block;
  }
  .account-label {
    // make sure to wrap instead of pushing the (up to 6 figure) value out of alignment:
    max-width: calc(100% - 30px);
    line-height: 1.4;
    display: flex;
    align-items: center;
    margin: 0 !important;
  }
}
