@import "./../../../styles/palette";

.quickbank-wrapper {
  border-radius: 4px;
  height: 9vh;
  text-align: center;
  vertical-align: middle;
  padding-left: 16px;
  padding-right: 16px;
  img.quickbank-logo {
    max-height: 4vh;
    max-width: 35vw;
    -webkit-filter: brightness(0); /* Safari 6.0 - 9.0 */
    filter: brightness(0) invert(1);
  }
  // Chase
  &.quickbank-ins_3 {
    background: linear-gradient(290.49deg, rgba(0, 0, 0, 0.2) -4.33%, rgba(0, 0, 0, 0) 93.18%),
      #3778c4;
  }
  // Bank of America
  &.quickbank-ins_127989 {
    background: linear-gradient(290.49deg, rgba(0, 0, 0, 0.2) -4.33%, rgba(0, 0, 0, 0) 93.18%),
      #c7392a;
  }
  // Citi
  &.quickbank-ins_5 {
    background: linear-gradient(290.49deg, rgba(0, 0, 0, 0.2) -4.33%, rgba(0, 0, 0, 0) 93.18%),
      #2b65ab;
  }
  // Cap One
  &.quickbank-h_ins_9 {
    display: flex;
    background: linear-gradient(290.49deg, rgba(0, 0, 0, 0.2) -4.33%, rgba(0, 0, 0, 0) 93.18%),
      #205084;
    img {
      margin: auto;
    }
  }
  // Wells Fargo
  &.quickbank-ins_4 {
    background: linear-gradient(290.49deg, rgba(0, 0, 0, 0.2) -4.33%, rgba(0, 0, 0, 0) 93.18%),
      #a62934;
    img {
      max-height: 10vh;
    }
  }
  // American Express
  &.quickbank-ins_10 {
    background: linear-gradient(290.49deg, rgba(0, 0, 0, 0.2) -4.33%, rgba(0, 0, 0, 0) 93.18%),
      #3275a2;
    img {
      max-height: 5vh;
    }
  }
}

.bank-search-item {
  background-color: $white;
  ion-thumbnail {
    .account-logo-icon {
      width: auto;
      height: auto;
      &.generic {
        min-width: 32px;
        min-height: 32px;
        margin-top: 25%;
        margin-left: 12px;
      }
    }
    --size: 60px;
    img {
      max-height: 100%;
      max-width: 100%;
      padding: 10px;
      padding-left: 0px;
    }
  }
}
