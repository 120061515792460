.add-property-modal {
  .ion-page {
    justify-content: flex-start;
  }
  .modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 12px;
    &::after {
      display: none;
    }
    .modal-title {
      margin: auto;
      padding-right: 38px; // to offset the close icon
    }
  }
  .property-type-options {
    display: flex;
    justify-content: space-around;
    > ion-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 70px;
      width: 70px;
      padding-top: 8px;
      border-radius: 50px;
      border: 2px solid #e6e6e6;
      &.selected {
        color: rgb(129, 105, 250);
        border: 2px solid rgb(129, 105, 250);
      }
    }
  }
  .fields-list .item {
    :last-child {
      text-align: right;
    }
  }
  .submit-button {
    margin-top: auto;
  }
}