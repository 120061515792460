@import "../../../styles/palette";

.modal-header {
  padding-top: 0.5rem;
  ion-title {
    img {
      height: 4vh;
    }
    padding: 3px 0;
  }
}
