.category-icon {
  margin: 0 10px;
}
.category-sub-icon {
  width: 24px;
  height: 24px;
  margin-right: -24px;
  position: relative;
  right: (24px + 10px);
}
