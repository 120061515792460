@import "../../../styles/palette";

ion-modal.addSubcategoryModal {
  ion-toolbar.modal-header {
    padding-top: 0px;
  }
  --max-width: 80vw;
  --max-height: 228px;
  --background: white;
  --backdrop-opacity: 50%;
}
