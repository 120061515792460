input.date-picker {
  border: none;
  background: #ffffff;
  outline: none;
  padding: 8px 0 !important;
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.33;
  }
}
