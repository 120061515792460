@font-face {
  font-family: "Austin-Medium";
  src: url(../assets/fonts/Austin/Austin-Medium-Web.woff2) format("woff2"),
    url(../assets/fonts/Austin/Austin-Medium-Web.woff) format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "IvyPresto";
  src: url("../assets/fonts/IvyPresto/IvyPresto Headline Semi Bold.woff2") format("woff2"),
    url("../assets/fonts/IvyPresto/IvyPresto Headline Semi Bold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url(../assets/fonts/Inter/Inter-Regular.ttf);
  src: url(../assets/fonts/Inter/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url(../assets/fonts/Inter/Inter-Medium.ttf);
  src: url(../assets/fonts/Inter/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url(../assets/fonts/Inter/Inter-SemiBold.ttf);
  src: url(../assets/fonts/Inter/Inter-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

$font-family-inter: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-ivy: IvyPresto, "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans",
  Arial, sans-serif;

@mixin inter-regular {
  font-family: $font-family-inter;
  font-weight: 400 !important;
}

@mixin inter-medium {
  font-family: $font-family-inter;
  font-weight: 500 !important;
}

@mixin inter-semibold {
  font-family: $font-family-inter;
  font-weight: 600 !important;
}

@mixin ivy-semibold {
  font-family: $font-family-ivy !important;
  font-weight: 600 !important;
}

@mixin font-xs {
  @include inter-regular;
  font-size: 12px !important;
  letter-spacing: 0.3px !important;
  line-height: 15px !important;
}

@mixin font-sm {
  @include inter-regular;
  font-size: 14px !important;
  letter-spacing: 0.5px !important;
  line-height: 17px !important;
}

@mixin font-md {
  @include inter-medium;
  font-size: 16px !important;
  letter-spacing: 0.5px !important;
  line-height: 19px !important;
}

@mixin font-lg {
  @include inter-semibold;
  font-size: 18px !important;
  letter-spacing: 0.5px !important;
  line-height: 22px !important;
}

@mixin font-xl {
  @include inter-semibold;
  font-size: 22px !important;
  letter-spacing: 0.5px !important;
  line-height: 27px !important;
}

@mixin font-title-3 {
  @include ivy-semibold;
  font-size: 24px !important;
  letter-spacing: 0.5px !important;
  line-height: 29px !important;
}

@mixin font-title-2 {
  @include ivy-semibold;
  font-size: 24px !important;
  letter-spacing: 0.5px !important;
  line-height: 29px !important;
  @media (min-width: 940px) {
    font-size: 40px !important;
    line-height: 48px !important;
  }
}

@mixin font-title-1 {
  font-family: Austin-Medium, $font-family-ivy !important;
  font-size: 56px !important;
  letter-spacing: 0.5px !important;
  line-height: 67px !important;
}

.font-xs {
  @include font-xs;
}

.font-sm {
  @include font-sm;
}

.font-md {
  @include font-md;
}

.font-lg {
  @include font-lg;
}

.font-xl {
  @include font-xl;
}

.font-title-3 {
  @include font-title-3;
}

.font-title-2 {
  @include font-title-2;
}

.font-title-1 {
  @include font-title-1;
}

// OVERRIDES
.medium {
  @include inter-medium;
}

.semibold,
.bold {
  @include inter-semibold;
}

.extra-bold {
  font-weight: 700 !important;
}

.inter-font {
  @include inter-medium;
}

.ivy-semibold {
  @include ivy-semibold;
}

// --- NOTE ---
// Because of the dynamic show/hide of the soft keyboard,
// it is not recommended to use vh (vertical height) for typography
// i.e., don't set font-size: 2vh;

/* Set variables for all modes */
:root,
body {
  /* Set the font family of the entire app */
  @include inter-medium;
}

h1 {
  @include font-lg;
  @include ivy-semibold;
}

h2 {
  @include font-md;
  @include ivy-semibold;
}

h3 {
  @include font-md;
}

h4,
h5,
h6 {
  @include font-sm;
}

// @media (min-width: 768px) and (max-width: 1199px) {
//   .font-xl {
//     font-size: 3em ;
//     line-height: -1.44 ;
//   }

//   .font-lg {
//     font-size: 1.3em ;
//     line-height: 1.3 ;
//   }

//   .font-md {
//     font-size: 1em ;
//     line-height: 1.4em ;
//   }
// }
