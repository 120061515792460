@import "./../../../styles/typography";

.net-worth-chart {
  width: 100%;
  height: 100%;
  tspan.month-tick {
    @include font-md;
  }
  tspan.value {
    @include font-sm;
  }
}
