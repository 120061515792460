.desktop-nav-menu {
  display: none;
  @media (min-width: 940px) {
    display: flex;
  }

  ion-label.menu-label {
    display: none;
  }
  max-width: 57px;
  ion-menu {
    border: none;
    min-width: 57px;
    ion-list {
      min-width: 269px;
    }
  }

  @media (min-width: 1440px) {
    max-width: 238px;
    ion-label.menu-label {
      display: unset;
    }
    ion-menu {
      min-width: 269px;
    }
  }

  z-index: 102;
  padding-top: 96px;
  #menu-item-more {
    .avatar-container {
      margin-left: 2px;
      margin-right: 13px;
    }
  }
}
