@import "../../../styles/palette";
@import "../../../styles/_typography";

.partial-modal {
  --backdrop-opacity: 0.5;
  &.height-sm {
    --max-height: 260px !important;
  }
  .modal-wrapper {
    position: absolute;
    width: 80vw;
    max-width: 600px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
    max-height: var(--max-height, 600px);
    @media (min-width: 940px) {
      height: var(--max-height);
      max-height: 600px;
    }
  }
  ion-footer.ios {
    max-height: 60px;
  }
}

.partial-modal-input {
  background-color: lightgray;
}

.celebration-modal .modal-wrapper {
  position: absolute;
  width: 90vw;
  height: 60vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 0;
  background: $spending-celebration;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  .celebration-modal-content .confetti-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 20vh;
      margin: 20px auto;
    }
    h1 {
      @include font-title-1;
      text-align: center;
    }
    h4,
    p {
      margin: 16px;
      @include font-md;
      text-align: center;
    }
  }
}

.celebration-modal-content::part(background) {
  background-color: $spending-celebration;
}
