@import "../../../styles/palette";

ion-modal#CreateRuleModal {
  ion-input {
    background-color: $light-gray;
    &[aria-disabled="true"] {
      background-color: $white;
    }
  }
  ion-col:nth-child(2) {
    border-left: 1px solid $light-gray;
  }
}

.rule-select-options .alert-radio-label {
  white-space: normal;
}
