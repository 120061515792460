.TransactionDetailPage {
  .fy-section-card {
    .transaction-detail-header {
      margin: 24px 16px 0;
    }
    .bottom-of-section {
      padding-bottom: 18px;
    }
  }
}
