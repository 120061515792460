@import "../../../styles/palette";

.newSpendingChallenge {
  width: 100%;
  &-type {
    border: 1px solid $light-gray;
    border-radius: 8px;
  }
  &-typeSelected {
    border: 1px solid $progress-fill;
    border-radius: 8px;
    background: rgba(237, 110, 71, 0.1);
  }
  &-length {
    border: 1px solid $light-gray;
    border-radius: 8px;
  }
  &-lengthSelected {
    border: 1px solid $primary-blue;
    border-radius: 8px;
    background: rgba(28, 127, 255, 0.1);
  }
  &-btns {
    display: flex;
    justify-content: space-around;
  }

  &-stack {
    border: 1px solid $brand-base;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &-input {
    border: 1px solid $brand-base;
    border-radius: 8px;
  }
  &-select {
    background-color: $quaternary;
    border-radius: 8px;
  }
  &-modal-content .confetti-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: 20vh;
      margin: 40px auto;
    }
    h4 {
      margin: 16px;
    }
  }
  &-home-card-header {
    margin: 0.3rem 0.1rem 1rem 0.1rem;
    ion-title {
      font-weight: 500;
      padding: 0;
    }
    .create-icon {
      position: absolute;
      right: 0;
    }
  }
}

.newSpendingChallenge-modal-content::part(background) {
  background-color: $spending-celebration;
}

.spendingChallenge {
  &-progressBar {
    @media (min-width: 940px) {
      --ion-margin: 0;
      padding: 0;
      max-width: 375px;
      margin: auto;
    }
    ion-progress-bar {
      --background: #eeeeee;
      --progress-background: #1c7fff;
      width: 70vw;
      margin-top: 4px;
    }
  }

  &-result {
    position: relative;
    &-icon {
      position: absolute;
      left: 52%;
      bottom: 15%;
    }
  }

  &-detail {
    &-header {
      // We need !important to override the desktop card white:
      background-color: $tertiary-light !important;
      width: 100%;
      h3 {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    &-reaction {
      display: flex;
      ion-button {
        display: flex;
        margin: 0 0 0 1rem;
        --padding-start: 8px;
        --padding-end: 8px;
        h4 {
          margin: 0 0.5rem;
        }
      }
    }
    &-avatar::part(native) {
      padding-left: 0;
    }
    &-duration {
      width: 100%;
      &-des {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        h4 {
          margin: 0;
        }
      }
    }
  }
}
