@import "../../../styles/palette";

.fun-fact {
  position: relative;
  ion-card-header {
    img {
      border-radius: 10px;
      margin-top: 16px;
    }
  }

  &-close {
    position: absolute;
    right: 16px;
  }

  p {
    color: $primary;
  }
}
