.goals-tab {
  --background: #f8f8f8;
}
.goals-intro-image {
  margin: auto;
}
.goals-intro-text {
  text-align: center;
  > div {
    max-width: 375px;
    margin: auto;
  }
}
.goals-intro-section {
  .goals-intro-image {
    width: 50%;
  }
  .goals-intro-text {
    text-align: left;
  }
}
.goals-testimonials {
  .goals-testimonial-section {
    max-width: 375px;
    margin: 32px auto !important;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    .top-image-section {
      position: relative;
      min-height: 154px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
    .name-section {
      background: #edeae0 !important;
      border-radius: 0px 4px 4px 0px;
      padding: 9px 16px;
      width: fit-content;
      position: absolute;
      bottom: -50px;
    }
    .text-section {
      padding: 72px 20.5px 37px;
    }
  }
  @media (min-width: 940px) {
    display: flex;
    .goals-testimonial-section {
      width: calc(50% - 8px);
      margin: 0 16px 0 0 !important;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}
