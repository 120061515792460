@import "../../../styles/palette";

.accounts-tab {
  .accounts-visibility-note {
    display: block;
    text-align: center;
    height: 32px;
    line-height: 32px;
  }
  .account-category-header {
    text-transform: capitalize;
    padding-right: 16px;
    h1:first-child {
      margin-right: auto;
    }
  }
  ion-footer {
    border-top: 1px solid $light-gray;
    background: $white;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
