@import "../../../styles/palette";

.newManualAccountInput::before {
  content: "$";
  padding-left: 1rem;
}

.manual-account-input {
  background-color: $quaternary;
  border-radius: 8px;
  padding: 0.5rem;
  margin: 0.5rem 0;
}
