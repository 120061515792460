@import "../../../styles/palette";

ion-modal.AddCustomCategoryModal {
  --max-width: 80vw;
  --max-height: 380px;
  --background: white;
  --backdrop-opacity: 50%;
  ion-thumbnail {
    --size: 60px;
    border: 1px $white solid;
    img {
      max-height: 100%;
      max-width: 100%;
      padding: 10px;
    }
    &.selected {
      background: $select-fill;
      border-radius: 50%;
      border: 1px $select-border solid;
    }
  }
}
