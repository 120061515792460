.edit-goal-page {
  .button-center {
    margin: 36px auto;
  }
}
ion-popover.wide {
  --max-width: 400px;
  --width: calc(100% - 24px);
  ion-button {
    min-width: fit-content;
  }
}
.alert-wrapper {
  .alert-message {
    white-space: pre-line;
    font-weight: 400;
    line-height: 130%;
  }
}
