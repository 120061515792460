:root {
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-xl: 160px;
  --spacing-ionic: 16px;
}

.fy-width-xs {
  width: var(--spacing-xs);
}

.fy-width-sm {
  width: var(--spacing-sm);
}

.fy-width-xl {
  width: var(--spacing-xl);
}

.fy-width-ionic {
  width: var(--spacing-ionic);
}

.fy-padding-vertical-xs {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}

.fy-padding-top-xs {
  padding-top: var(--spacing-xs);
}

.fy-padding-bottom-xs {
  padding-bottom: var(--spacing-xs);
}

.fy-padding-vertical-sm {
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-sm);
}

.fy-padding-top-sm {
  padding-top: var(--spacing-sm);
}

.fy-padding-bottom-sm {
  padding-bottom: var(--spacing-sm);
}

.fy-margin-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

.fy-margin-horizontal {
  margin-left: auto;
  margin-right: auto;
}

.h-3 {
  height: 24px;
}
