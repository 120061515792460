ion-avatar#BillerLogoAvatar {
  border: 1px solid black;
}

img#BillerLogoAvatarImg {
  object-fit: contain;
}

img#BillsharkLogoAvatarImg {
  object-fit: contain;
}

ion-item#BillerStatusRow {
  padding-right: 15px;
}
