@import "../../../styles/palette";

ion-grid#BudgetCardContentGrid {
  ion-row {
    height: 12vh;
    ion-col:first-child {
      height: 100%;
    }
  }
  h3.font-md {
    margin-bottom: 5px;
  }
  span#inner-border {
    display: block;
    border-top: 1px solid $medium-gray;
    margin-left: 15%;
    margin-right: 15%;
  }
  h3.font-sm {
    padding-top: 5px;
  }
}
