@import "./../../../styles/typography";

ion-item.fy-field {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  ion-label {
    // using 16px since labels will actually be scaled to 0.75 of the original size (resulting in 12px)
    @include inter-regular;
    &.md {
      margin-bottom: 16px;
    }
  }
  ion-text {
    width: 100%;
  }
  ion-text {
    > img {
      max-width: 24px;
      height: 24px;
      margin-bottom: -5px;
    }
    div.ownership-avatars {
      display: inline-flex;
      max-width: 24px;
      height: 15px;
    }
    padding-bottom: 8px;
  }
  input[type="date"] {
    width: calc(100%);
  }
  ion-input input[type="text"],
  ion-input input[type="number"] {
    --padding-bottom: 0px;
  }
  --border-width: 0 0 1px 0 !important;
  --border-color: #e6e6e6;
  --inner-border-width: 0;
  > ion-icon {
    margin-right: 0;
  }
}
