.reaction-container {
  @media (max-width: 940px) {
    .popover-content {
      width: unset;
    }
  }
  img {
    display: inline-block;
    width: 2.2rem;
    margin: 0.2rem;
  }
}
.reaction-closeIcon {
  margin-left: 0.5rem;
  color: #45bcf3;
}
