@import "../../../styles/palette";

.splitTransaction {
  &-category {
    background-color: #eeeeee;
    border-radius: 8px;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 1.5rem;
    }
  }

  &-delete {
    &-icon {
      color: $warning-text;
    }
    &-button {
      height: 2rem;
      width: 100%;
    }
  }

  &-input {
    &-amount {
      background-color: $quaternary;
      border-radius: 8px;
    }
    &-column {
      max-height: 40px;
      align-self: flex-start;
    }
  }
}
