ion-popover#BillShareSettingsPopover {
  --width: 90vw;
}

ion-item.BillItem {
  padding-top: 5px;
  padding-bottom: 5px;
  ion-label:first-of-type {
    min-width: 65%;
  }
}
